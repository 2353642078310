<template>
    <div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
        <div class="over_bgc">
            <!-- 顶部栏 -->
            <top-banner theme="white" @backHome="backHome"></top-banner>
            <div class="x_between">
                <!-- 左边 -->
                <div class="left" style="flex:1">
                    <!-- 标题 -->
                    <title-enzh enTitle="FEEDBACK" zhTitle="问题反馈" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
                    <div class="idea wrap_x_between">
                        <!-- 图片上传 -->
                        <div>
                            <el-upload action="#" list-type="picture-card" :auto-upload="false" :file-list="fileList" :on-change="handleChange">
                                <img slot="default" src="../assets/image/feedback/add_img_icon.svg" style="width:100%">
                                <div slot="file" slot-scope="{file}">
                                    <img class="el-upload-list__item-thumbnail" :src="file.url">
                                    <span class="el-upload-list__item-actions">
                                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                            <i class="el-icon-zoom-in"></i>
                                        </span>
                                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                                            <i class="el-icon-delete"></i>
                                        </span>
                                    </span>
                                </div>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl">
                            </el-dialog>
                        </div>
                        <!-- 选择反馈类型 -->
                        <div style="width:80%">
                            <div class="title">请选择反馈类型</div>
                            <el-radio-group v-model="radio" style="width:100%">
                                <div class="wrap_x_between">
                                    <div v-for="(item,index) in radioList" :key="index" class="radio_item">
                                        <el-radio :label="item.id">{{item.label}}</el-radio>
                                    </div>
                                    <div class="empty"></div>
                                    <div class="empty"></div>
                                </div>
                            </el-radio-group>
                        </div>
                    </div>
                    <!-- 输入框 -->
                    <div class="wrap_x_between" style="width:100%">
                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">姓名</span>
                                <span class="require_icon">*</span>
                            </div>
                            <input type="text" class="input_rect" v-model="user_name" style="width:100%">
                        </div>
                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">电话</span>
                                <span class="require_icon">*</span>
                            </div>
                            <input type="text" class="input_rect" v-model="user_phone" style="width:100%">
                        </div>
                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">已有BIMOM账户</span>
                            </div>
                            <input type="text" class="input_rect" v-model="user_account" style="width:100%">
                        </div>
                        <div class="input_box" style="width:44%">
                            <div>
                                <span class="lable">邮箱</span>
                            </div>
                            <input type="text" class="input_rect" v-model="user_email" style="width:100%">
                        </div>
                        <div class="input_box" style="width:100%">
                            <div>
                                <span class="lable">企业</span>
                                <span class="require_icon">*</span>
                            </div>
                            <input type="text" class="input_rect" v-model="user_company" style="width:100%">
                        </div>
                        <div class="input_box" style="width:100%">
                            <div>
                                <span class="lable">问题描述</span>
                                <span class="require_icon">*</span>
                            </div>
                            <textarea cols="30" rows="4" v-model="feed_description" style="width:100%;"></textarea>
                        </div>
                    </div>
                    <!-- 提交按钮 -->
                    <div class="submit" @click="sendmsg">提交反馈</div>
                </div>
                <!-- 右边 -->
                <div class="right" style="flex:1">
                    <!-- 标题 -->
                    <title-enzh enTitle="CONTACT US" zhTitle="联系我们" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
                    <!-- 图片 -->
                    <img src="@/assets/image/home/sevenPage/addressPic.jpg" class="map_size">
                    <!-- 公司信息 -->
                    <div class="company_info">
                        <div class="address">成都公司：四川省成都市高新区天府五街</div>
                        <div class="wrap_x_between">
                            <div v-for="(item,index) in infoList" :key="index" class="info_item y_center">
                                <img :src="item.icon" class="item_icon">
                                <div v-text="item.name" class="item_name"></div>
                            </div>
                            <div class="empty_company_info"></div>
                        </div>
                    </div>
                    <div class="copyright">版权所有 成都悟空和展昭科技有限公司  丨 技术支持 成都悟空和展昭科技有限公司</div>
                </div>
            </div>
        
        
        </div>
    </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
export default {
    components:{
        topBanner,
        titleEnzh
    },
    data(){
        return {
            fileList:[],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            radioList:[
                {id:1,label:"程序BUG"},
                {id:2,label:"功能建议"},
                {id:3,label:"内容意见"},
                {id:4,label:"网络问题"},
                {id:5,label:"账户问题"},
                {id:6,label:"其他"},
            ],
            radio: 1,
            infoList:[
               {icon:require("../assets/image/home/sevenPage/phone.svg"),name:"15959358326"},
               {icon:require("../assets/image/home/sevenPage/wx.svg"),name:"fbr0321"},
               {icon:require("../assets/image/home/sevenPage/qq.svg"),name:"546570287"},
               // {icon:require("../assets/image/home/sevenPage/phone.svg"),name:"点击咨询在线客服"},
               {icon:require("../assets/image/home/sevenPage/email.svg"),name:"546570287@qq.com"},
           ],
            user_name:"",
            user_phone:"",
            user_account:"",
            user_email:"",
            user_company:"",
            feed_description:"",
        }
    },
    methods: {
        handleRemove(file) {
            // console.log(file);
            // // filelist.remove(file);
            // console.log(this.fileList);
            console.log(this.fileList[1].name);
            let index = this.fileList.findIndex(fileItem => {
                return fileItem.uid === file.uid
            })
            this.fileList.splice(index, 1)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
            // filelist.push(file);
            console.log(this.fileList[0].name);
        },
        handleChange(file, fileList) {
            this.fileList = fileList
            console.log(this.fileList[0].name);
        },
        submitImg(){
            console.log(this.fileList[0].name);
        },
        backHome(){
            this.$router.push("/")
        },
        sendFiles(){
            let param = {
                feedbackid: "aaaaaaaaaaa",
            };
            let config = {
                headers: {
                    // 'Content-Type': 'application/json'
                    'Content-Type': 'multipart/form-data'
                }
            };
            //let file = this.fileList;// this.$refs.upload.uploadFiles.pop().raw;//这里获取上传的文件对象

            let formData = new FormData();
            this.fileList.forEach((file) => {
                formData.append('files', file.raw);
                console.log(file.name);
            })
            // formData.append("file",file);
            formData.append("feedbackid",this.user_name);

            this.axios.post(this.VUE_APP_BASE_API + '/Feedback/uploadFiles', formData,config).then((res) => {
                if (res.data.resultCode == 0) {
                    this.dialogFormVisible = false;
                     alert("文件提交成功");
                } else {
                    alert("提交失败:  "+ res.data.resultMsg);
                }
            });
        },
        sendmsg(){
            let files="";
            this.fileList.forEach((file) => {
                // formData.append('files', file.raw);
                files=files+file.raw.name+";";
                // console.log(file.name);
            })
            console.log(this.fileList[0].name);
            console.log(this.user_name);
            let param = {
                feed_type: this.radioList[this.radio-1].label,
                user_name: this.user_name,
                user_phone: this.user_phone,
                user_account: this.user_account,
                user_email:this.user_email,
                user_company:this.user_company,
                feed_description:this.feed_description,
                feed_files:files,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            console.log(this.currentRow);
            this.axios.post(this.VUE_APP_BASE_API + '/Feedback/insertFeedback', JSON.stringify(param), config).then((res) => {
                if (res.data.resultCode == 0) {
                    this.sendFiles();
                    this.dialogFormVisible = false;
                    alert("反馈提交成功");
                } else {
                    alert("提交失败:  "+ res.data.resultMsg);
                }
            });
        }
    }
}
</script>

<style scoped>
.idea >>> .el-upload-list--picture-card .el-upload-list__item{
    width: 120px;
    height: 120px;
}
.idea >>> .el-upload--picture-card{
    width: 120px;
    height: 120px;
    background-color: transparent;
    border: none;
}
.idea >>> .el-radio__label{
    font-size: 18px;
    color: #fff;
}
</style>
<style lang="scss" scoped>
.empty{
    min-width: 110px;
    height: 0px;
}
.empty_company_info{
    width: 250px;
    height: 0;
}
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../assets/image/feedback/feedback_bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.left{
    padding-right:60px; 
    .idea{
        margin: 20px 0;
        padding: 20px;
        border-radius: 6px;
        border: 2px solid rgba(121, 121, 121, 1);
        .title{
            color: #fff;
            text-align: left;
            font-size: 19px;
        }
        .radio_item{
            min-width: 110px;
            margin-top: 20px;
        }
    }
}
.right{
    padding-left: 60px;
    .map_size{
        width: 100%;
        height: 470px;
        margin: 30px 0;
        border-radius: 10px;
    }
    .company_info{
        font-family: '幼圆 Bold', '幼圆', sans-serif;
        font-size: 18px;
        color: #797979;
        margin-bottom: 20px;
        .address{
            margin-bottom:30px;
            font-weight: bold;
        }
        .info_item{
            font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
            margin-bottom:26px;
            width: 250px;
            .item_icon{
                width:30px;
                margin-right:20px
            }
            .item_name{
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
    .copyright{
        font-family: '幼圆 Bold', '幼圆', sans-serif;
        font-size: 16px;
        color: #797979;
        font-weight: bold;
    }
}


// 输入框
.input_box{
    // margin-top: 10px;
    .input_rect{
        height: 40px;
        padding: 0 20px;
        border-radius: 6px;
        margin-top: 10px;
        background: rgba($color: #fff, $alpha: .5);
        color: #fff;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .lable{
        font-family: '幼圆', sans-serif;
        color: #fff;
        margin-right: 6px;
        font-size: 22px;
    }
    .require_icon{
        font-size: 26px;
        color: #02A7F0;
        vertical-align: middle;
    }
}
textarea{
    background: rgba($color: #fff, $alpha: .5);
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    outline:none;
    padding: 20px;
    margin-top: 10px;
}
.submit{
    width: 240px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
    font-size: 18px;
    color: #797979;
    border: 1px solid #797979;
    letter-spacing: 4px;
    border-radius: 20px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    margin-top: 20px;
    &:hover{
        color: #fff;
        border: 1px solid #fff;
    }
}
</style>